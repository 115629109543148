import type { KeycloakAuth, Like, TokenLikes } from '$lib/types';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { getDefaultAxios, getDefaultAxiosWithToken } from './AxiosService';

export class LikeService {
	keyCloack: KeycloakAuth | undefined;
	likesURL = `/markets/likes`;
	axios: AxiosInstance;

	constructor(keyCloack?: KeycloakAuth) {
		if (keyCloack) {
			this.keyCloack = keyCloack;
			this.axios = getDefaultAxiosWithToken(keyCloack);
		} else {
			this.axios = getDefaultAxios();
		}
	}

	async LikeOrder(orderId: string) {
		let body: any = {
			resourceURI: '/markets/orders/' + orderId,
			user: '@me'
		};
		const resp = await this.axios.post<any, AxiosResponse<Like>, any>(this.likesURL, body, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return resp.data;
	}

	async UnlikeOrder(likes: TokenLikes) {
		let resp = await this.axios.delete<void>(likes.links['unlike'].url);
		if (resp.status === 200) {
			return true;
		}
		return false;
	}
}
