<script lang="ts">
	import { HeartIcon } from '$lib/components/icons';
	import DisplayIcon from '$lib/components/icons/DisplayIcon.svelte';
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher<{ liked: boolean }>();

	export let likes: number = 0;
	export let like = false;

	$: colorCss = like ? 'fill-current text-error-500' : 'fill-current text-gray-70';
	$: linksString = likes.toString();

	function onClickHandler() {
		dispatch('liked', !like);
	}
</script>

<div
	role="button"
	tabindex="0"
	class="cursor-pointer flex items-center text-gray-70"
	on:keypress
	on:click|stopPropagation={onClickHandler}
>
	{#if like}
		<DisplayIcon size={20} viewBoxWidth={24} viewBoxHeight={24} icon={HeartIcon} css={colorCss} />
	{:else}
		<svg width="20" height="20" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.83268 1.5C3.53185 1.5 1.66602 3.34667 1.66602 5.625C1.66602 7.46417 2.39518 11.8292 9.57268 16.2417C9.70125 16.3199 9.84885 16.3613 9.99935 16.3613C10.1498 16.3613 10.2974 16.3199 10.426 16.2417C17.6035 11.8292 18.3327 7.46417 18.3327 5.625C18.3327 3.34667 16.4669 1.5 14.166 1.5C11.8652 1.5 9.99935 4 9.99935 4C9.99935 4 8.13352 1.5 5.83268 1.5Z"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	{/if}

	<div class="mx-1 text-xs">{linksString}</div>
</div>
